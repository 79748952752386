.splash-title {
  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;

    &.homescreen {
      animation-name: splash;
      animation-duration: 3s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
    &.project {
      animation-name: splash-project;
      animation-duration: 2.2s;
      // animation-delay: 1s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }

    &.bilder {
      fill: white;
    }
  }

  &__logo,
  &__claim {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
  }

  &__logo {
    width: 80vw;
    max-width: 1160px;
  }

  &__claim {
    text-align: center;
    background-color: transparent;
    // position: relative;

    h2,
    h2 + span {
      margin-bottom: 0;
      font-size: 2em;
      text-transform: uppercase;
      font-weight: 200;
      text-align: center;
      white-space: nowrap;
      animation-name: splash-project;
      animation-duration: 2s;

      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }

    // h2 {
    // }
    span {
      // display: inline-block;
      display: flex;
      justify-content: center;
    }
    span:before,
    span:after {
      content: "";
      // margin-right: 0.4em;
      animation-name: splash-project-spans;
      animation-duration: 2s;
      // animation-delay: 2s;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;
      display: inline-block;
      align-self: center;
      // width: 100%;
      width: 20px;
      height: 1px;
      background-color: var(--header-bg);
    }

    span:after {
      margin-left: 0.4em;
    }
    span:before {
      margin-right: 0.4em;
    }
  }
}

@keyframes splash {
  0% {
    background-color: white;
    fill: var(--header-bg);
  }
  50% {
    background-color: white;
    fill: var(--header-bg);
  }
  60% {
    background-color: transparent;
    fill: transparent;
  }
  80% {
    fill: transparent;
  }
  100% {
    fill: white;
  }
}
@keyframes splash-project {
  0% {
    background-color: white;
    color: var(--header-bg);
  }
  90% {
    background-color: white;
    // color: var(--header-bg);
    color: transparent;
  }

  100% {
    color: transparent;
    background-color: transparent;
  }
}

@keyframes splash-project-spans {
  0% {
    background-color: var(--header-bg);
  }
  90% {
    background-color: transparent;
    // background-color: var(--header-bg);
  }
  100% {
    background-color: transparent;
  }
}
