.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
}

[hidden] {
  display: none;
}

// Positioning img
.object-bottom {
  object-position: bottom;
}
.object-center {
  object-position: center;
}
.object-left {
  object-position: left;
}
.object-left-bottom {
  object-position: left bottom;
}
.object-left-top {
  object-position: left top;
}
.object-right {
  object-position: right;
}
.object-right-bottom {
  object-position: right bottom;
}
.object-right-top {
  object-position: right top;
}
.object-top {
  object-position: top;
}

.span_1-2 {
  grid-column: 1/3;
}
.span_2-3 {
  grid-column: 2/4;
}

// Hong Kong ?
.item-1 {
  grid-column: 1/2;
  grid-row: 1/2;
}

.item-6 {
  grid-row: 3/4;
}

.item-8 {
  grid-row: 4/5;
  grid-column: 1/2;
}
.item-10 {
  grid-row: 5/6;
}
.item-13 {
  grid-row: 6/7;
  grid-column: 2/3;
}
.item-14 {
  grid-row: 6/7;
  grid-column: 3/4;
}

// Grid row helper class

.row_01 {
  grid-row: 1/2;
}
.row_02 {
  grid-row: 2/3;
}
.row_03 {
  grid-row: 3/4;
}

.row_04 {
  grid-row: 4/5;
}
.row_05 {
  grid-row: 5/6;
}

.row_06 {
  grid-row: 6/7;
}

.row_07 {
  grid-row: 7/8;
}

.row_08 {
  grid-row: 8/9;
}

.row_08 {
  grid-row: 9/10;
}

.row_09 {
  grid-row: 10/11;
}

.row_10 {
  grid-row: 11/12;
}

// Grid column helper class

.colspan_01-06 {
  grid-column: 1/6;
}
.colspan_01-07 {
  grid-column: 1/7;
}
.colspan_01-08 {
  grid-column: 1/08;
}
.colspan_01-09 {
  grid-column: 1/09;
}
.colspan_01-10 {
  grid-column: 1/10;
}
.colspan_01-11 {
  grid-column: 1/11;
}
.colspan_01-12 {
  grid-column: 1/12;
}
.colspan_01-13 {
  grid-column: 1/-1;
}

// 2 bis
.colspan_02-06 {
  grid-column: 2/6;
}
.colspan_02-07 {
  grid-column: 2/7;
}
.colspan_02-08 {
  grid-column: 2/08;
}
.colspan_02-09 {
  grid-column: 2/09;
}
.colspan_02-10 {
  grid-column: 2/10;
}
.colspan_02-11 {
  grid-column: 2/11;
}
.colspan_02-12 {
  grid-column: 2/12;
}
.colspan_02-13 {
  grid-column: 2/-1;
}

// 3 bis
.colspan_03-05 {
  grid-column: 3/5;
}
.colspan_03-06 {
  grid-column: 3/6;
}
.colspan_03-07 {
  grid-column: 3/7;
}
.colspan_03-08 {
  grid-column: 3/8;
}
.colspan_03-09 {
  grid-column: 3/9;
}
.colspan_03-10 {
  grid-column: 3/10;
}
.colspan_03-11 {
  grid-column: 3/11;
}
.colspan_03-12 {
  grid-column: 3/12;
}
.colspan_03-13 {
  grid-column: 3/-1;
}
// 4 bis
.colspan_04-07 {
  grid-column: 4/07;
}
.colspan_04-08 {
  grid-column: 4/08;
}
.colspan_04-09 {
  grid-column: 4/09;
}
.colspan_04-10 {
  grid-column: 4/10;
}
.colspan_04-11 {
  grid-column: 4/11;
}
.colspan_04-12 {
  grid-column: 4/12;
}

// 5 bis
.colspan_05-08 {
  grid-column: 5/08;
}
.colspan_05-09 {
  grid-column: 5/09;
}
.colspan_05-10 {
  grid-column: 5/10;
}
.colspan_05-11 {
  grid-column: 5/11;
}
.colspan_05-12 {
  grid-column: 5/12;
}
.colspan_05-13 {
  grid-column: 5/-1;
}

// 6bis
.colspan_06-11 {
  grid-column: 6/11;
}
.colspan_06-12 {
  grid-column: 6/12;
}
.colspan_06-13 {
  grid-column: 6/-1;
}

// 7 bis
.colspan_07-11 {
  grid-column: 7/11;
}
.colspan_07-12 {
  grid-column: 7/12;
}
.colspan_07-13 {
  grid-column: 7/-1;
}
.colspan_07-10 {
  grid-column: 7/10;
}

// 8 bis
.colspan_08-11 {
  grid-column: 8/11;
}
.colspan_08-12 {
  grid-column: 8/12;
}

// Object fit
.left-top {
  object-position: left top;
}

.rowspan-2-4 {
  grid-row: 2/4;
}
.rowspan-3-5 {
  grid-row: 3/5;
}

.rowspan-4-6 {
  grid-row: 4/6;
}
.rowspan-4-7 {
  grid-row: 4/6;
}

//hide on Desktop
.hide-on-desktop {
  @media (min-width: $breakpoint) {
    display: none;
  }
}
