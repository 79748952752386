/* position dots in carousel */
.flickity-page-dots {
  display: none;
  // display: block;
  // bottom: 30px !important;
  @media (min-width: 768px) and (min-height: 376px) {
    display: block;
    bottom: 30px !important;
  }
}
/* white circles */
.flickity-page-dots .dot {
  // display: inline-block;
  width: 12px;
  height: 12px;
  opacity: 1;
  background: transparent;
  border: 2px solid white;
}
/* fill-in selected dot */
.flickity-page-dots .dot.is-selected {
  background: white;
}

/* flickity-fade */

.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1;
}

/* Own Slider Styles */
.main-carousel {
  height: 100vh;
  position: relative;
  a {
    text-decoration: none;
  }
  // display: none;
}
.carousel-cell {
  width: 100%; /* full width */
  // height: calc(100vh - var(--header-height)); /* height of carousel */
  height: 100vh; /* height of carousel */
  // background-color: lightpink;
  padding-top: var(--header-height);
  // @media only screen and (max-width: 812px) and (orientation: landscape) {
  //   padding-top: 0;
  // }

  // margin-top: calc(var(--header-height) * 5);
  img {
    height: 100%;
    object-fit: cover;
    // object-position: top right;
  }
}

/* Overlays */
.slide {
  &-heading {
    font-size: 1.5em;
    font-weight: 300;
    position: absolute;
    display: inline-block;
    bottom: 20vh;
    @media (min-width: $breakpoint) {
      bottom: 10vh;
    }
    left: 50%;
    transform: translateX(-50%);
    color: white;
    &:visited,
    &:link,
    &:hover {
      color: white;
    }
  }
}
