.menu {
  &-wrapper {
    position: absolute; // not fixed because of old ios?
    display: block;
    top: 0;
    left: 0;
    height: var(--header-height);
    width: 100vw;
    background-color: var(--header-bg);
    z-index: 3;
    // margin-top: env(safe-area-inset-top);

    a {
      text-decoration: none;
    }
  }

  &-trigger {
    height: var(--header-height);
    // background-color: pink;
    background-color: var(--header-bg);
    width: 100%;
    // padding: env(safe-area-inset-top) env(safe-area-inset-right)
    //   env(safe-area-inset-bottom) env(safe-area-inset-left);
    border: none;
    // outline: none;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: env(safe-area-inset-top);
    cursor: pointer;
    z-index: 9;
    outline: none;

    .trigger-icon {
      width: 27px;
      stroke-width: 0.35rem;
      stroke: white;
      transition: 0.4s;
    }

    // wird hoffentlich nur bei Tastaturbedienung gezeigt?
    // &:focus {
    //   border: 1px solid red;
    // }

    &.is-active .trigger-icon {
      transform: rotate(45deg);
      transition: 0.5s;
    }
  }

  &-wrapper {
    .navigation {
      position: absolute;
      top: -100vh;
      display: block;
    }
  }

  &-wrapper .navigation.is-active {
    left: 50%;
    top: 50%;
    transform: translate(-50%, 50%);
    transition: top 0.5s ease-in;

    text-align: center;
    list-style: none;
    font-weight: 300;
    font-size: 1.5em;
    padding-left: 0;
    // padding-bottom: 0;

    @media only screen and (max-height: 414px) and (max-width: $breakpoint) and (orientation: landscape) {
      top: 100%;
      left: 50%;
      font-size: 90%;
      font-weight: 800;
      transform: translate(-50%, 250%);
      width: 90vw;
      display: flex;
      margin: 0;
      padding-bottom: 0;
      justify-content: space-between;
      a {
        display: inline;
        padding: 2em;
      }
    }

    @media (min-width: $breakpoint) {
      margin-top: 0;
      top: 100%;
      transform: translateX(-50%);
      padding-top: 4em;

      padding-bottom: 4em;
      background-color: var(--header-bg);
      width: 745px;
    }

    li {
      margin-bottom: 2em;
      @media (min-width: $breakpoint) {
        padding: 1em;
        display: block;
        &:not(:last-of-type) {
          margin-bottom: 1em;
        }
      }
    }

    a {
      width: 200px;
      color: var(--menu-font-color);
      text-transform: uppercase;
      letter-spacing: 0.2em;
      &:hover {
        text-shadow: 1px 0 0 white;
      }
    }
  }
}

.main-wrapper {
  // &:before {
  //   background-color: hsla(0, 0%, 100%, 0);
  //   background-color: green;

  //   pointer-events: none;
  // }
  &.is-active {
    overflow: hidden;
  }

  &.is-active:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background-color: rgba(255, 0, 0, 0.85);
    pointer-events: all;
    z-index: 1;
    transition: 0.5s;
  }

  &.is-active .splash-title__logo {
    transition: 0.5s;
    fill: transparent !important;
  }
  &.is-active .claim-project {
    transition: 0.5s;
    z-index: 0;
  }
}
