.text-section {
  padding: 2em;
  text-align: initial;
  display: grid;
  grid-template-columns: 1fr;
  // grid-row-gap: 20vh;
  // font-size: 100%;

  @media (min-width: $breakpoint) {
    font-size: 150%;
  }

  > *:not(:first-child) {
    margin-top: 20vh;
  }

  p:first-child {
    margin-top: 1em;
  }

  .about-entry__wrapper {
    @media (min-width: $breakpoint) {
      max-width: 60em;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-template-columns: repeat(5, 90px) 20px auto;
      grid-row-gap: 3em;
      .heading-lang {
        grid-column: 5/8;
        grid-row: 2/3;

        div {
          grid-column: 2/3;
          grid-row: 1/2;
        }
        &:nth-child(4) {
          grid-row: 3/4;
        }
      }
    }

    .txt {
      // padding-top: 2em;
      // display: none;
      grid-column: 1/3;
      @media (min-width: $breakpoint) {
        grid-column: 2/3;
        padding-top: 2em;
      }
      grid-row: 2/3;

      p {
        // font-size: 1em;
        &:not(:first-child) {
          margin-top: 0.5em;
        }
      }
    }
  }

  img {
    width: 70%;
    max-width: 40ch;
    @media (min-width: $breakpoint) {
      width: 100%;
      grid-column: 1/6;
      grid-row: 1/2;
    }
  }

  [class^="heading"] {
    display: grid;
    grid-template-columns: 120px 1fr;

    + * {
      // font-size: 1.5em;
      font-weight: 700;
      margin: 0;
      padding: 0;
      margin-bottom: 2em;
      text-transform: uppercase;
      // grid-column: 2/3;
      // grid-row: 1/2;
    }

    &:before {
      content: "";
      display: inline-block;
      position: relative;
      top: 11px;
      margin-right: 20px;
      height: 10px;
      background-color: black;
    }

    p {
      // font-size: 1rem;
      font-weight: 400;
      text-transform: initial;
      grid-column: 1/-1;
      // max-width: 60ch;
    }
  }

  .heading-about {
    margin-top: -4em;
    @media (min-width: $breakpoint) {
      margin-top: 0;
      grid-template-rows: auto 1fr;
      font-size: 1.3em;
    }

    h1 {
      margin: 0;
      margin-bottom: 1.5em;
      margin-top: -0.2em;
      color: black;
      white-space: nowrap;
      @media (min-width: $breakpoint) {
        grid-column: 1/3;

        margin: 0;
      }
    }

    @media (min-width: $breakpoint) {
      grid-column: 7/8;
      &:before {
        display: none;
      }
    }

    .subtitle_about {
      grid-column: 2/3;
      grid-row: 2/3;
      margin-bottom: 4em;
      // font-size: 1.4em;
      font-weight: 200;
      @media (min-width: $breakpoint) {
        grid-column: 1/3;
        grid-row: 2/2;
        padding-left: 0.2em;
      }
    }
  }

  .about-facts__wrapper {
    & > *:not(:first-child) {
      margin-top: 2em;
      // color: red;
      @media (min-width: $breakpoint) {
        margin-top: 0;
      }
    }

    h2 {
      font-size: 1em;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 1em;
      margin-bottom: 0.5em;
    }
    @media (min-width: 1024px) {
      display: flex;
      justify-content: space-between;
      // max-width: 1500px;
      margin-left: 5vw;
      margin-right: 5vw;
    }

    ul {
      list-style: none;
      padding-left: 0;
      min-width: 200px;
    }
    .about_facts__vita {
      li:nth-child(3) {
        margin-top: 2em;
        // color: red;
      }
    }
    .about_facts__kunden {
      p {
        margin-top: 2em;
        max-width: 300px;
      }
    }
  }

  .about-philosophie__wrapper {
    // background-color: lightcyan;
    text-align: center;
    // font-size: 2rem;
    // margin-top: 5em;
    font-size: 120%;
    @media (min-width: $breakpoint) {
      font-size: 170%;
    }
    text-transform: uppercase;

    p:not(:first-child) {
      margin-top: 2em;
      // color: green;
    }

    > div:after {
      content: "";
      display: inline-block;
      width: 100px;
      height: 10px;
      background-color: black;
      margin-top: 3em;
      margin-bottom: 1em;
    }

    h2 {
      margin-left: -2em;
      margin-right: -2em;
      background-color: var(--header-bg);
      color: white;
      font-size: 2em;
      margin-bottom: 2em;
      @media (min-width: $breakpoint) {
        margin-bottom: 3em;
      }
      // font-size: 4rem;
    }
    p {
      max-width: 1500px;
      margin-left: auto;
      margin-right: auto;
      font-weight: 700;
      // font-size: 1.5em;
    }
  }
}
