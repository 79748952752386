* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "Nunito Sans", sans-serif;
  font-size: 100%;
  background-color: var(--text-bg);
  line-height: 1.7;
  // overscroll-behavior: none;
  // touch-action: pan-x;
  color: var(--text-color);
}

body.white {
  background-color: var(--menu-font-color);
  // background-color: red;
  color: var(--header-bg);
}

/*new*/
html body {
  position: fixed;
  overflow: hidden;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

a {
  display: inline-block;
  color: inherit;
}


h1 {
  font-weight: normal;
}