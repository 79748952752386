/* nunito-sans-300 - latin */
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Nunito Sans Light"), local("NunitoSans-Light"),
    url("../fonts/nunito-sans-v5-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/nunito-sans-v5-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-sans-regular - latin */
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Nunito Sans Regular"), local("NunitoSans-Regular"),
    url("../fonts/nunito-sans-v5-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/nunito-sans-v5-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-sans-700 - latin */
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Nunito Sans Bold"), local("NunitoSans-Bold"),
    url("../fonts/nunito-sans-v5-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/nunito-sans-v5-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-sans-800 - latin */
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Nunito Sans ExtraBold"), local("NunitoSans-ExtraBold"),
    url("../fonts/nunito-sans-v5-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/nunito-sans-v5-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-sans-900 - latin */
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Nunito Sans Black"), local("NunitoSans-Black"),
    url("../fonts/nunito-sans-v5-latin-900.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/nunito-sans-v5-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
