$breakpoint: 1024px;

:root {
  // Colors
  --header-bg: rgba(37, 40, 42, 1);
  --menu-font-color: white;
  --text-color: --header-bg;
  --text-bg: --menu-font-color; // --link-color: var(--font-color);
  --menu-bglayer: rgba(255, 0, 0, 0.85);
  // --hover-color: #ff0000;
  @media (min-width: $breakpoint) {
    --text-color: white;
    --text-bg: var(--header-bg);
  }

  // Sizes
  --header-height: 4rem;
}
