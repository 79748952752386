.main-wrapper {
  width: 100vw;
  height: 100vh;
  opacity: 1;
  overflow-y: auto;
  overflow-x: hidden;
  // overscroll-behavior: none;
  -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
  -ms-overflow-style: -ms-autohiding-scrollbar;
  // touch-action: pan-x;
  // background-color: var(--header-bg);
  /*enable for text etc*/
  // padding-top: env(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);

  animation: fadein 0.7s;

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.main {
  text-align: center;
}

.bilder__wrapper,
.text__wrapper {
  padding-top: calc(var(--header-height) * 1);
  padding-left: 0.5em;
  padding-right: 0.5em;
  @media (min-width: $breakpoint) {
    padding-top: calc(var(--header-height) * 3);
  }

  // a:focus:not(.grid-item) {
  //   border: 5px solid red;
  // }

  &.portfolio {
    a {
      display: block;
      &:not(first-of-type) {
        margin-top: 2em;
        @media (min-width: $breakpoint) {
          margin-top: 0;
        }
      }
    }
  }

  &.grid {
    @media (min-width: $breakpoint) {
      a {
        margin-top: 0;
      }

      img {
        height: 100%;
        width: 100%;
        display: block;
        object-fit: cover;
      }

      display: grid;
      width: 90vw;
      max-width: 1490px;
      margin: 0 auto;
      grid-template-columns: repeat(3, 1fr);
      // // grid-auto-rows: minmax(500px, 500px);

      grid-gap: 7.3em 45px;
    }
    // @media (min-width: 1200px) {
    //   grid-auto-rows: minmax(550px, 550px);
    // }
  }
}

.header-project {
  margin-bottom: 3em;
  @media (min-width: $breakpoint) {
    margin-bottom: 10em;
  }
}

.single-project {
  position: relative;
  margin-top: 2em;

  a:not(:last-of-type) {
    margin-bottom: 300px;
  }
  @media (min-width: $breakpoint) {
    padding-left: 0;
    padding-right: 0;

    & > &__grid {
      width: 90vw;
      max-width: 1800px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-row-gap: 20vh;

      a {
        margin: 0;
      }
    }
  }
}

.claim {
  &-project {
    width: 100%;
    text-align: center;
    padding: 45vh 0;
    z-index: 2;
    position: relative;
    span {
      display: inline-block;
      font-size: 5em;
      text-transform: uppercase;
      font-weight: 600;
      @media (min-width: $breakpoint) {
        width: initial;
        font-size: 8em;
      }
    }
  }
}

// Lightbox
.chocolat-wrapper {
  .chocolat {
    &-overlay {
      opacity: 0.97;
      background: linear-gradient(transparent var(--header-height), var(--header-bg) 0%);
    }

    &-bottom,
    &-left {
      display: none !important;
    }

    &-right {
      width: 100%;
      height: 100vh;
      top: calc(var(--header-height) - 7px);
      margin-top: var(--header-height);
      background-image: none;
      opacity: 0.5;
      margin-top: 0;
    }

    &-close {
      width: 100%;
      height: var(--header-height);
      transform: rotate(-45deg);
    }

    &-img {
      transform: scale(0.95);
    }
  }
}
.chocolat-parent {
  // Fix für schlecht beschnittene Bilder (schneidet 1px ab)
  img {
    outline: 2px pink solid;
    outline: 2px var(--text-bg) solid;
    outline-offset: -1px;
  }

  @media (max-width: 1023px) {
    pointer-events: none;
  }
  a {
    display: block;
  }
}
.chocolat-open {
  .trigger-icon {
    transform: rotate(45deg);
    transition: 0.5s;
  }
}

// Hong Kong Grid

.grid-row_01 {
  grid-row: 1/2;
  grid-column: 1/-1;
}
.grid-row_02 {
  grid-row: 2/3;
  grid-column: 4/10;
}
.grid-row_03 {
  grid-row: 3/4;
  grid-column: 1/8;
}

.grid-row_04 {
  grid-row: 4/5;
  grid-column: 7/-1;
}
.grid-row_05 {
  grid-row: 5/7;
  grid-column: 1/-1;
}

.grid-row_06 {
  grid-row: 7/8;
  grid-column: 5/-1;
}

.grid-row_07 {
  grid-row: 8/9;
  grid-column: 4/12;
}

.kontakt {
  .text-section {
    @media (min-width: $breakpoint) {
      max-width: 60em;
      margin-left: auto;
      margin-right: auto;
    }
    > div:not(:first-child) {
      margin-top: 1em;
    }
  }
}
.kontakt-entry__wrapper {
  .kontakt-name {
    font-size: 1.5rem;
    font-weight: 700;
    + span {
      margin-bottom: 1em;
      display: inline-block;
      font-size: 1.5em;
      ~ span {
        font-size: 1.5em;
      }
    }
  }
  span:not(.inline) {
    display: block;
  }

  .kontakt-address {
    margin-bottom: 2em;
  }
}
.social {
  display: inline-block;

  img.social-icon {
    width: 1.6em;
  }
  a:not(:first-of-type) {
    margin-left: 2em;
  }
}
.impressum__wrapper,
.datenschutz__wrapper {
  padding-top: 4em;
  max-width: 320px;
  @media (min-width: 500px) {
    max-width: initial;
  }
  h2,
  h3 {
    text-transform: uppercase;
    font-size: 1em;
    // word-wrap: break-word;
    // color: red;
    // display: inline-block;
    max-width: 320px !important;
    @media (min-width: 500px) {
      max-width: initial;
      font-size: inherit;
    }
  }
  h2 {
    margin-top: 2em;
  }
  h3 {
    margin-top: 1em;
  }
}

.footer-title {
  &__wrapper {
    text-align: center;
    display: block;
    position: relative;
    width: 100%;
    height: 100vh;
  }

  &__claim {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2,
    h2 + span {
      margin-bottom: 0;
      font-size: 2em;
      text-transform: uppercase;
      white-space: nowrap;
      font-weight: 300;
      text-align: center;
      color: var(--header-bg);
      @media (min-width: $breakpoint) {
        color: white;
      }
    }

    span {
      display: flex;
      justify-content: center;
    }
    span:before,
    span:after {
      content: "";
      align-self: center;
      width: 20px;
      height: 1px;
      background-color: var(--header-bg);
      @media (min-width: $breakpoint) {
        background-color: white;
      }
    }

    span:after {
      margin-left: 0.4em;
    }
    span:before {
      margin-right: 0.4em;
    }
  }
}

.kontakt_wrapper {
  text-align: center;
  @media (min-width: $breakpoint) {
    text-align: initial;
    width: 800px;
  }
  margin: 10em auto 0 auto;

  a {
    display: block;
  }

  .social {
    margin-top: 2em;
  }
}

.home {
  background-color: var(--header-bg);
}

body.page-404 {
  background: url(../images/assets/tanja-tremel_404.jpg) no-repeat center center fixed;
  background-size: cover;

  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  .wrap404 {
    position: absolute;
    font-size: 140%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 3em;
    color: black;
    background-color: hsla(0, 0%, 100%, 0.7);
    box-shadow: 0 20px 30px -15px rgba(0, 0, 0, 0.5), 0 40px 40px -20px rgba(0, 0, 0, 0.4),
      0 70px 50px -30px rgba(0, 0, 0, 0.3), 0 40px 60px -5px rgba(0, 0, 0, 0.3);

    button {
      margin-top: 3em;
      padding: 1em;
      background-color: var(--menu-bglayer);
      color: white;
      font-weight: bold;
      border: none;
    }
  }
}

.header-project {
  h1,
  h2 {
    text-transform: uppercase;
  }
}
